import React from 'react'
import theme from "theme";
import { Theme, Image, Box, Text, Section, Link, Input, Button, Label } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Formspree, SocialMedia } from "@quarkly/components";

import { useLoginState } from '../userStateContext';

import BackgroundImg from '../../resources/background/bg.png';
import localFavicon from '../../resources/Icons/logo_32x32.ico';
import SOMALogo from '../../resources/Logos/Logo-bgRem.png';

export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);

    return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"forgetPasswordPage"} />
		<Helmet >
			<title>
				Forgot Password
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"journal-submission"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
        <Section 
	        //background="url(https://uploads.quarkly.io/648e94f3588b2d001958b466/images/bg.png?v=2023-10-21T11:51:02.910Z) center/cover scroll"
            background={BackgroundImg}
            width="100%"
            height= "1500"
            backgroundposition= "center"
            backgroundsize= "cover"
            justify-content="center"
        >
            <Section>
                <Override slot="SectionContent" />
                <Box
                    min-width="100px"
                    min-height="100px"
                    order="0"
                    align-self="stretch"
                    display="flex"
                >
                    <Box display="flex" align-items="center" sm-flex-wrap="wrap">
                        <Link
                            opacity="0.6"
                            text-decoration-line="initial"
                            text-align="left"
                            hover-text-decoration-line="underline"
                            font="--base"
                            color="--dark"
                            white-space="nowrap"
                            href="https://www.societyofmedicalanatomists.com/"
                            margin="1px 0px 0px 10px"
                        >
                            <Image
                                src={SOMALogo}
                                display="block"
                                max-height="150px"
                                max-width="150px"
                                srcSet=""
                                sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
                            />
                        </Link>
                    </Box>
                    <Box min-width="100px" min-height="100px" align-self="center">
                        <Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
                            National Journal of Society of Medical Anatomists
                        </Text>
                    </Box>
                    <Box min-width="100px" min-height="100px">
                    </Box>
                </Box>
            </Section>
            <Section
                width="100%"
                height= "100%"
                backgroundposition= "center"
                backgroundsize= "cover"
                justify-content="center"
            >
                <Box>
                    <Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
                        <h2>Reset your password</h2>
                        <h5>Enter your email address and we will send you a new password</h5>
                    </Text>
                </Box>
            </Section>
            <Section
                width="100%"
                height= "100%"
                backgroundposition= "center"
                backgroundsize= "cover"
                justify-content="center"
            >
                <Box
                    min-width="100px"
                    min-height="100px"
                    order="0"
                    align-self="center"
                    display="flex"
                >
                    <Formspree
                        errorMessage="Something went wrong"
                        completeText="Success"
                        endpoint="meqveqwr"
                        display="flex"
                        width="100%"
                        align-self="center"
                    >
                        <Text>Email address</Text>
                        <Input
                            placeholder="Email ID"
                            width="400px"
                            height="20%"
                            margin="0px 4px 10px 0px"
                            type="email"
                            md-width="100%"
                            lg-width="100%"
                            name="email"
                        /><br/><br/>
                        <Button id="sub_btn"  width="400px" lg-width="100%">
                            Send password reset email
                        </Button>
                    </Formspree>
				</Box>
            </Section>
            <Section
                width="100%"
                height= "100%"
                backgroundposition= "center"
                backgroundsize= "cover"
                justify-content="center"
            >
                <Box
                    min-width="100px"
                    min-height="100px"
                    order="0"
                    align-self="center"
                    display="flex"
                >
                    <Text margin="0px 0 16px 0" text-align="center">
                        First time? <Link href="/loginPages/registerPage">Create an account</Link>.<br/><br/>
                        <Link href="/loginPages/homePage">Back to Homepage</Link>.
                    </Text>
                </Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    order="0"
                    align-self="center"
                    display="flex"
                >
				</Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    order="0"
                    align-self="center"
                    display="flex"
                >
				</Box>
                <Box
                    min-width="100px"
                    min-height="100px"
                    order="0"
                    align-self="center"
                    display="flex"
                >
				</Box>
            </Section>
            <Section
                margin="0 0 0 0"
                padding="16px 0 16px 0"
                box-sizing="border-box"
                quarkly-title="Footer"
                justify-content="center"
                align-self="bottom"
            >
                <Override
                    slot="SectionContent"
                    min-width="auto"
                    width="100%"
                    margin="0px 32px 0px 32px"
                    md-margin="0px 16px 0px 16px"
                />
                <Box
                    width="100%"
                    grid-template-columns="repeat(2, 1fr)"
                    sm-grid-gap="16px"
                    display="grid"
                    grid-gap="32px"
                >
                    <Box display="flex" align-items="center" sm-flex-wrap="wrap">
                        <Image
                            width="28px"
                            height="28px"
                            src={SOMALogo}
                            srcSet=""
                            sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
                        />
                        <Link
                            opacity="0.6"
                            text-decoration-line="initial"
                            text-align="left"
                            hover-text-decoration-line="underline"
                            font="--base"
                            color="--dark"
                            white-space="nowrap"
                            href="https://www.societyofmedicalanatomists.com/"
                            margin="1px 0px 0px 10px"
                        >
                            <strong>SOMA</strong>
                        </Link>
                    </Box>
                    <SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
                        <Override slot="link" background="none" border-radius="50%" />
                        <Override slot="icon" color="--dark" />
                    </SocialMedia>
                </Box>
            </Section>
        </Section>
	</Theme>; 
});